<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container v-if="!loading">
      <v-row class="left">
        <v-col class="mb-4">
          <p class="text-h5">
            Realizowane i planowane szkolenia
          </p>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col class="mb-4">
          <v-card>
            <v-data-table
              :headers="headers"
              :items="events"
              item-key="id"
              :loading="loading"
              class="elevation-1"
            >
              <template slot="item.index" scope="props">
                {{ props.index + 1 }}
              </template>

              <template v-slot:item.dates="{ item }">
                {{ item.event_start_date }} - {{ item.event_end_date }}
              </template>

              <template v-slot:item.plan="{ item }">
                <v-icon v-if="item.schedules.length > 0">
                  mdi-check
                </v-icon>
              </template>

              <template v-slot:item.bur="{ item }">
                <v-icon v-if="item.description.burNumber">
                  mdi-check
                </v-icon>
              </template>

              <template v-slot:item.action="{ item }">
                <div class="d-flex">
                  <v-btn
                    class="ma-1"
                    color="primary"
                    outlined
                    v-bind:href="
                      'https://simp.edu.pl/wp-admin/post.php?action=edit&post=' +
                        item.post_id
                    "
                    target="_blank"
                  >
                    Edytuj na SIMP
                    <v-icon right>
                      mdi-launch
                    </v-icon>
                  </v-btn>
                  <v-btn
                    :to="'/courses/' + item.id"
                    class="ma-1"
                    color="primary"
                  >
                    Edytuj
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <SnackbarStatus
        :show="snackbar.show"
        :color="snackbar.color"
        :message="snackbar.message"
      />
    </v-container>
  </div>
</template>

<script>
import EventsService from "@/api/EventsService";
import SnackbarStatus from "@/components/SnackbarStatus";
import common from "@/mixins/common";

export default {
  name: "Events",
  mixins: [common],
  components: {
    SnackbarStatus
  },
  data: () => ({
    events: [],
    headers: [
      { text: "", value: "index", width: "20px" },
      { text: "Nazwa szkolenia", value: "event_name" },
      { text: "Data szkolenia", value: "dates", width: "200px" },
      { text: "Lokalizacja", value: "event_location.location_town" },
      { text: "Plan", value: "plan" },
      { text: "BUR", value: "bur" },
      { text: "", value: "action", width: "200px" }
    ]
  }),
  methods: {
    loadEvents() {
      EventsService.loadEvents()
        .then(res => {
          console.log(res);
          this.events = res;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.showSnackbar("Request to API failed!", "warning");
        });
    }
  },
  created() {
    this.loadEvents();
  }
};
</script>
