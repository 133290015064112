import ajax from "@/ajax";

export default {
  loadEvents() {
    return ajax.get("/api/events").then(res => {
      return res.data;
    });
  },
  loadEventById(id) {
    return ajax
      .get("/api/events/" + id, {
        params: { id: id }
      })
      .then(res => {
        return res.data;
      });
  },
  loadSubsribersByEventId(id) {
    return ajax
      .get("/api/events/subscribers/" + id, {
        params: { id: id }
      })
      .then(res => {
        return res.data;
      });
  },
  loadBlocktypes() {
    return ajax.get("/api/events/blocktypes").then(res => {
      return res.data;
    });
  },
  saveEventCertificates(id, description) {
    return ajax
      .post("/api/events/certificates/" + id, description)
      .then(res => {
        return res.data;
      });
  },
  saveEventSchedule(id, schedule) {
    return ajax.post("/api/events/schedule/" + id, schedule).then(res => {
      return res.data;
    });
  },
  generateDocs(id) {
    return ajax
      .get("/api/events/docs/" + id, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/zip"
        }
      })
      .then(res => {
        return res.data;
      });
  }
};
