var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(!_vm.loading)?_c('v-container',[_c('v-row',{staticClass:"left"},[_c('v-col',{staticClass:"mb-4"},[_c('p',{staticClass:"text-h5"},[_vm._v(" Realizowane i planowane szkolenia ")])])],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',{staticClass:"mb-4"},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.events,"item-key":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.index",fn:function(props){return [_vm._v(" "+_vm._s(props.index + 1)+" ")]}},{key:"item.dates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.event_start_date)+" - "+_vm._s(item.event_end_date)+" ")]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [(item.schedules.length > 0)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item.bur",fn:function(ref){
var item = ref.item;
return [(item.description.burNumber)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","outlined":"","href":'https://simp.edu.pl/wp-admin/post.php?action=edit&post=' +
                      item.post_id,"target":"_blank"}},[_vm._v(" Edytuj na SIMP "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-launch ")])],1),_c('v-btn',{staticClass:"ma-1",attrs:{"to":'/courses/' + item.id,"color":"primary"}},[_vm._v(" Edytuj ")])],1)]}}],null,false,1302360570)})],1)],1)],1),_c('SnackbarStatus',{attrs:{"show":_vm.snackbar.show,"color":_vm.snackbar.color,"message":_vm.snackbar.message}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }